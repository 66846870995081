import { notification } from 'ant-design-vue'

notification.config({
  placement: 'top',
  top: '55px',
})

const [notificationApi] = notification.useNotification()

export default {
  open: notificationApi.open,
  error: notification.error,
  info: notification.info,
  warning: notification.warning,
  success: notification.success,
}
