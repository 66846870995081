import type { Locale } from 'date-fns'
import { enUS as localeEN, ru as localeRU } from 'date-fns/locale'
import type { AvailableLocale, AvailableLocaleOption } from '../types'

export const LOCALE_LS_KEY = 'locale'
export const DEFAULT_LOCALE = 'ru'
export const SEPARATOR = ' | '
export const availableLocales: AvailableLocale[] = ['ru', 'en']

export const AVAILABLE_LOCALE_OPTIONS: AvailableLocaleOption[] = [
  {
    id: 'en',
    value: 'en',
    title: 'English',
    icon: 'en',
  },
  {
    id: 'ru',
    value: 'ru',
    title: 'Русский',
    icon: 'ru',
  },
]

export const AVAILABLE_LOCALE_PICKER_OPTIONS: Record<AvailableLocale, Locale> = {
  ru: localeRU,
  en: localeEN,
}
