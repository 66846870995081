import type { Report, SystemVersion } from '../types/api'
import axiosInstance from '@/plugins/axios'

export async function fetchReportList(businessProcessType: string): Promise<Report[]> {
  const response = await axiosInstance.get<Report[]>(
    `/reports/${businessProcessType}`,
  )

  return response.data
}

export async function apiFetchSystemVersion(): Promise<SystemVersion> {
  const url = '/data/version'
  const response = await axiosInstance.get(url)

  return response.data
}
